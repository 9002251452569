<template>
    <div>
        <div class="main-title">用户列表</div>
        <div class="content-list-page page-content">
            <div class="list-head-box">
                <div data-v-69874ad0="" class="list-head">
                    <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
                    <ul data-v-69874ad0="">

                    </ul>
                </div>
            </div>


            <div class="padding-t-sm padding-b-sm">
                <span class="fl-l">
                    <el-select v-model="searchType" slot="prepend" placeholder="选择类型" size="mini">
                        <el-option label="微信昵称" :value="1"></el-option>
                        <el-option label="UID" :value="2"></el-option>
                    </el-select>
                    <el-input class="margin-l-sm" v-model="userName" size="mini" style="width:160px" clearable>
                    </el-input>
                </span>

<!--                <span class="margin-l-sm fl-l">-->
<!--                    <el-date-picker size="small" v-model="value2" type="daterange" align="right" unlink-panels-->
<!--                                    range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"-->
<!--                                    :picker-options="pickerOptions">-->
<!--                    </el-date-picker>-->
<!--                </span>-->

                <span class="margin-l-sm fl-l">
                    <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button>
                </span>

                <span class=" fl-r">

                </span>

                <div style="clear: both"></div>

            </div>


            <div class="table-content">

                <el-table :header-cell-style="{padding:0}" :cell-class-name="rowItem" ref="singleTable"
                          :data="tableData" highlight-current-row @current-change="handleCurrentChange" v-loading="loading"
                          style="width: 100%;background: #fff;">
                    <el-table-column label="ID" property="id" width="80">
                    </el-table-column>
                    <el-table-column width="80" label="头像">
                        <template slot-scope="scope">
                            <el-avatar shape="square" :size="50" :src="scope.row.avatar_url" icon="el-icon-user-solid">
                            </el-avatar>
                        </template>
                    </el-table-column>
                    <el-table-column property="index" label="用户名">
                        <template slot-scope="scope">
                            <span>{{scope.row.name}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column property="index" label="可用学币">
                        <template slot-scope="scope">
                            <span>{{scope.row.credit}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column property="index" label="已购课程">
                        <template slot-scope="scope">

                            <el-tooltip class="item" effect="dark" placement="top">
                                <div slot="content" v-html="course(scope.row.course)"></div>
                                <span>{{scope.row.course[0] === undefined ? '' : scope.row.course[0]}}</span>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column property="index" label="性别" width="80">
                        <template slot-scope="scope">
                            <span v-if="scope.row.gender === 0">保密</span>
                            <span v-if="scope.row.gender === 1">男</span>
                            <span v-if="scope.row.gender === 2">女</span>

                        </template>
                    </el-table-column>
                    <el-table-column property="index" label="权限" width="80">
                        <template slot-scope="scope">
                            <span v-if="scope.row.role === 0">普通用户</span>
                            <span v-if="scope.row.role === 1">超级权限</span>
                            <span v-if="scope.row.role === 5">销售权限</span>
                            <span v-if="scope.row.role === 6">教师权限</span>

                        </template>
                    </el-table-column>
                    <el-table-column property="address" label="绑定手机号">
                        <template slot-scope="scope">
                            <span v-if="scope.row.is_bind_phone == 1">已绑定</span>
                            <span v-if="scope.row.is_bind_phone == 0">未绑定</span>
                        </template>
                    </el-table-column>
                    <el-table-column property="created_at" label="注册时间" width="150">
                    </el-table-column>
                    <el-table-column property="index" label="vip时间">
                        <template slot-scope="scope">
                            <span>{{scope.row.vip_free_end_at === null ? '无' : scope.row.vip_free_end_at}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column property="address" label="功能限制" width="100">
                        <template slot-scope="scope">
                            <span v-if="scope.row.is_ban == 0" class="state-up"><i></i>无限制</span>
                            <span v-if="scope.row.is_ban == 1" class="state-down"><i></i>已禁言</span>
                        </template>
                    </el-table-column>
                    <el-table-column property="address" label="状态" width="100">
                        <template slot-scope="scope">
                            <span v-if="scope.row.status == 1" class="state-up"><i></i>正常</span>
                            <span v-if="scope.row.status == 2" class="state-down"><i></i>限制登录</span>
                        </template>
                    </el-table-column>
                </el-table>

            </div>

            <div class="table-batch">
                <span class="fl-r">
                    <el-pagination small background :current-page="page" :page-sizes="[10, 20]" :page-size="pageSize"
                                   layout="total, sizes, prev, pager, next, jumper" @size-change="setPageSize"
                                   @current-change="setPage" :total="total">
                    </el-pagination>
                </span>
                <div style="clear: both"></div>
            </div>
        </div>

        <el-dialog append-to-body title="关闭vip会员" :visible.sync="closeVipDialog" width="30%">
            <div>
                <el-input v-model="closeRemark" placeholder="关闭原因"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="hideCloseVip" size="mini">取 消</el-button>
                <el-button type="primary" @click="closeVip()" size="mini" :loading="closeBtn">确 定</el-button>
            </span>
        </el-dialog>


        <el-dialog append-to-body title="开通权限" :visible.sync="closeRoleDialog" width="30%">
            <div>
                <el-select v-model="role" placeholder="请选择权限" size="mini" clearable>
                    <el-option label="超级权限" value="1"></el-option>
                    <el-option label="销售权限" value="5"></el-option>
                    <el-option label="教师权限" value="6"></el-option>
                </el-select>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeRoleDialog = false" size="mini">取 消</el-button>
                <el-button type="primary" size="mini" :loading="closeBtn" @click="setRole()">确 定</el-button>
            </span>
        </el-dialog>




        <el-dialog append-to-body title="开通VIP" :visible.sync="bookDialog" width="30%">
            <div style="padding:10px">
                <div>
                    <span>VIP类型:</span>
                    <span style="width: 40px;display: inline-block;"></span>
                    <el-select v-model="VipData.module" placeholder="VIP类型">
                        <el-option v-for="item in modules" :key="item.id" :label="item.name" :value="item.module">
                        </el-option>
                    </el-select>
                </div>
                <div style="height: 12px"></div>
                <div>
                    <span>赠送天数:</span>
                    <span style="width: 40px;display: inline-block;"></span>
                    <el-input-number v-model="VipData.days" :min="1" size="mini" label="赠送天数"></el-input-number>
                </div>
                <div style="height: 12px"></div>
                <div>
                    <span>备注:</span>
                    <span style="width: 40px;    display: inline-block;"></span>
                    <el-input type="textarea" :rows="2" placeholder="备注" v-model="VipData.remark">
                    </el-input>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="bookDialog = false" size="mini">取 消</el-button>
                <el-button type="primary" size="mini" :loading="closeBtn" @click="setVip()">确 定</el-button>
            </span>
        </el-dialog>


        <el-dialog append-to-body title="赠送书籍" :visible.sync="giveBookDialog" width="50%" style="padding:10px">
            <el-form :model="giveAudioBookData" ref="giveAudioBookData" label-width="100px" class="demo-ruleForm">
                <el-form-item>
                    <select-audio-book :userId="selectUser.id" @onSelect="onSelect" @onDelete="onDelete">
                    </select-audio-book>
                </el-form-item>
                <el-form-item>
                    <span>赠送天数: </span>
                    <el-input-number v-model="days" :min="1" size="mini" label="赠送天数"></el-input-number>
                </el-form-item>
                <el-form-item style="padding:20px">
                    <el-button type="primary" @click="giveBook">提交</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

    </div>
</template>

<script>
    import { mapActions } from "vuex";
    import selectAudioBook from "../../components/selectAudioBook.vue"
    export default {
        name: "list",
        components: {  selectAudioBook },
        data() {
            return {
                giveBookDialog: false,
                bookDialog: false,
                closeBtn: false,
                closeUser: null,
                closeRemark: '',
                closeVipDialog: false,
                closeRoleDialog: false,
                btnLoad: false,
                selectUser: {
                    id: 0
                },
                credit: 0,
                bindBtn: false,
                page: 1,
                pageSize: 10,
                total: 0,
                searchType: 1,
                userName: '',
                courseList: [],
                userCourse: [],
                courseIds: [],
                loading: false,
                bindLoading: false,
                imageUrl: config.imageUrl,
                options: [{
                    value: '选项1',
                    label: '黄金糕'
                }],
                modules: [],
                VipData: {
                    days: 1
                },
                giveAudioBookData: {},
                value: '',
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                value2: '',
                tableData: [],
                currentRow: null,
                role: '',
                bookIds: [],
                days: 1
            }
        },
        methods: {
            ...mapActions('user', ['getUserList', 'setUserBan', 'setUserStatus', 'setUserCredit', 'setUserRole', 'closeUserVip', 'giveVip']),
            ...mapActions('hearBook', ['giveBooks']),
            ...mapActions('common', ['vipSelectList']),
            ...mapActions('course', ['getCourseList', 'bindCourse']),
            setCurrent(row) {
                this.$refs.singleTable.setCurrentRow(row);
            },
            onSelect(e) {
                console.log(e);
                this.bookIds = e
            },
            onDelete(e) {
                this.bookIds.splice(e, 1)
            },
            async setVip() {
                let form = {
                    userId: this.selectUser.id,
                    ...this.VipData
                }
                const { data, res_info } = await this.giveVip(form)
                this.bookDialog = false
                if (res_info != 'ok') return
                this.$message.success('开通成功')
            },
            async giveBook() {
                let bookIds = []
                this.bookIds.forEach(v => {
                    bookIds.push(v.id)
                })
                let form = {
                    userId: this.selectUser.id,
                    bookIds,
                    days: this.days
                }
                const { data, res_info } = await this.giveBooks(form)
                this.giveBookDialog = false
                if (res_info != 'ok') return
                this.$message.success('赠送成功')

            },
            handleCurrentChange(val) {
                this.currentRow = val;
            },
            headeCellStyle({ row, rowIndex }) {
                return '{padding:0}';
            },
            rowItem() {
                return 'row-item';
            },
            selectCourse(row) {
                this.courseList = []
                this.userCourse = []
                this.courseIds = []
                this.bindLoading = true
                let list = []
                this.getCourseList({ user_id: row.id }).then(res => {

                    res.data.course.forEach(course => {
                        course.chack = false
                        res.data.user_course.forEach(item => {
                            if (course.id == item.course_id) {
                                this.courseIds.push(item.course_id)
                            }
                        })
                        list.push(course)

                    })

                    this.courseList = list
                    this.userCourse = res.data.user_course
                    this.bindLoading = false
                })
            },
            submitBind(row) {
                this.bindBtn = true
                let userId = row.id
                console.log(this.courseIds)

                let data = {
                    user_id: userId,
                    course_ids: this.courseIds
                }
                this.bindCourse(data).then(res => {
                    if (res.ret == 0) {
                        this.$message.success('绑定成功')
                    } else {
                        this.$message.error('绑定失败')
                    }
                    this.bindBtn = false
                })
            },
            showCloseVip(row) {
                this.closeUser = row
                this.closeVipDialog = true
            },
            hideCloseVip() {
                this.closeVipDialog = false
            },
            closeVip() {
                this.closeBtn = true
                let data = {
                    userId: this.closeUser.id,
                    remark: this.closeRemark
                }
                this.closeUserVip(data).then(res => {
                    if (res.ret === 0) {
                        this.closeBtn = false
                        this.hideCloseVip()
                        this.$message.success('取消成功')
                        this.getList()
                    }
                })
            },
            course(course) {
                let name = ''
                if (course) {
                    course.forEach(item => {
                        name = name + '【' + item + '】' + '<br />'
                    })
                }
                return name
            },
            setBan(row, isBan) {
                this.loading = true
                let data = {
                    user_id: row.id,
                    is_ban: isBan === 0 ? 0 : 1,
                }
                let message = isBan === 1 ? '用户已被限制发言' : '用户已解除禁言限制'
                this.setUserBan(data).then(res => {
                    this.$message.success(message)
                    this.getList()
                })
            },
            setStatus(row, status) {
                this.loading = true
                let data = {
                    id: row.id,
                    status: status
                }
                let message = status == 1 ? '解除用户登录限制' : '已限制用户登录'
                this.setUserStatus(data).then(res => {
                    this.$message.success(message)
                    this.getList()

                })

            },
            presentedCredit(e) {
                let data = {
                    userId: e.id,
                    credit: this.credit
                }
                console.log(e)
                console.log(data)
                this.btnLoad = true
                this.setUserCredit(data).then(res => {
                    console.log(res)
                    this.btnLoad = false
                    this.$message.success('赠送成功！')
                    this.getList()
                })

            },
            setRole() {
                console.log(this.role)
                let data = {
                    role: this.role,
                    userId: this.selectUser.id
                }
                this.setUserRole(data).then(res => {
                    this.closeRoleDialog = false
                    this.$message.success('设置成功')
                    this.getList()
                })
            },
            search() {
                this.tableData = []
                this.page = 1
                this.getList()
            },
            getList() {
                let data = {
                    page: this.page,
                    pageSize: this.pageSize,
                }
                if (this.searchType === 1) {
                    data.name = this.userName
                } else {
                    data.uid = this.userName
                }
                // if(this.userName != ''){
                //     data.name = this.userName
                // }
                this.loading = true
                this.getUserList(data).then(res => {
                    this.total = res.data.total
                    this.tableData = res.data.list

                    this.loading = false
                })
            },
            setPageSize(pageSize) {
                this.pageSize = pageSize
                this.getList()
            },
            setPage(page) {
                this.page = page
                this.getList()
            },
            async selectList() {
                const { data } = await this.vipSelectList()
                this.modules = data
            },
        },
        mounted() {
            this.getList()
            this.selectList()
        }
    }
</script>

<style>
    .el-popover {
        min-width: 80px;
    }
</style>
